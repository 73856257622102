<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <el-select v-model="productTypeId" placeholder="商品类型" style="width: 240px;"
                        @change="loadProducts(true)">
                        <el-option :value="null" label="全部商品类型" />
                        <el-option v-for="productType in productTypes" :key="productType.id" :value="productType.id"
                            :label="productType.name">
                            <span :style="{marginLeft: (productType.id.length-4)*4+'px'}">{{productType.name}}</span>
                        </el-option>
                    </el-select>
                    <search-box v-model="key" class="ml-2" style="width: 240px;" @search="loadProducts(true)" />
                </div>
            </div>
            <el-table :data="products.data" class="mt-2">
                <el-table-column width="90">
                    <template #default="scope">
                        <el-image :src="scope.row.pictureUrl" :preview-src-list="[scope.row.pictureUrl]" fit="fill"
                            style="width: 64px; height: 64px;" />
                    </template>
                </el-table-column>
                <el-table-column label="名称">
                    <template #default="scope">
                        <div :title="scope.row.name" class="text-truncate">
                            <router-link :to="'/product/detail?id='+scope.row.id" target="_blank">
                                {{scope.row.name}}
                            </router-link>
                        </div>
                        <div class="text-info">
                            [{{scope.row.productTypeName}}]
                            {{scope.row.specification}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="number" label="编号" width="150" />
                <el-table-column prop="unit" label="计量单位" width="120" />
                <el-table-column prop="price" label="价格（元）" width="120" />
                <el-table-column label="操作" fixed="right" width="90">
                    <template #default="scope">
                        <el-button type="text" @click="showAuditProductDialog(scope.row)">
                            审核退回
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="products.totalRecords"
                :current-page="productsPagination.currentPage" :page-size="productsPagination.pageSize" class="mt-3"
                @current-change="productsPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="auditProductDialogVisible" title="审核" :close-on-click-modal="false" width="960px"
            top="0px">
            <iframe v-if="auditProductDialogVisible" :src="'/#/product/detail?id='+product.id" frameborder="0"
                class="d-block border border-primary rounded w-100" style="height: calc(100vh - 280px);"></iframe>

            <el-form class="mt-2">
                <el-form-item label="审核备注">
                    <el-input v-model="auditRemark" placeholder="审核备注" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="danger" @click="auditProduct(false)">审核退回</el-button>
                <el-button @click="auditProductDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                productTypes: [],
                productTypeId: null,
                key: null,
                products: {},
                productsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                product: {},
                auditRemark: null,
                auditProductDialogVisible: false,
            };
        },
        methods: {
            async loadProductTypes() {
                let response = await this.$axios.get('/api/ProductType/GetProductTypes');
                this.productTypes = response.data;
            },
            async loadProducts(resetPage = false) {
                if (resetPage) {
                    this.productsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/Product/GetProducts', {
                    params: {
                        status: 3,
                        isOnSale: true,
                        productTypeId: this.productTypeId,
                        key: this.key,
                        pageIndex: this.productsPagination.currentPage - 1,
                        pageSize: this.productsPagination.pageSize,
                    }
                });
                this.products = response.data;
            },
            productsPaginationCurrentChange(page) {
                this.productsPagination.currentPage = page;
                this.loadProducts();
            },
            showAuditProductDialog(product) {
                this.product = JSON.parse(JSON.stringify(product));
                this.auditRemark = null;
                this.auditProductDialogVisible = true;
            },
            async auditProduct(isPass) {
                if (!confirm(`确定要审核${isPass ? '通过' : '退回'}吗？`)) {
                    return;
                }

                await this.$axios.post('/api/Product/AuditProduct', {
                    id: this.product.id,
                    isPass: isPass,
                    auditRemark: this.auditRemark,
                });
                this.auditProductDialogVisible = false;
                this.loadProducts();
            },
        },
        created() {
            this.loadProductTypes();
            this.loadProducts();
        },
    };
</script>